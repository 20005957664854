import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuCollapsed: false,
  mobileMenu: false,
  selectedMenu: "dashboard",
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
    },
    setMobileMenu: (state, action) => {
      state.mobileMenu = action.payload;
    },
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
      state.mobileMenu = false;
    },
  },
});

export const { setMenuCollapsed, setSelectedMenu, setMobileMenu } =
  layoutSlice.actions;

export default layoutSlice.reducer;
