import React from "react";
import "./styles.scss";
import AppMenuFooter from "./AppMenuFooter";
import AppMenuHeader from "./AppMenuHeader";
import AppMenuItems from "./AppMenuItems";
import { useSelector } from "react-redux";

const AppMenu = () => {
  const mobileMenu = useSelector((state) => state.layout.mobileMenu);
  return (
    <div className={`app-menu ${mobileMenu ? "mobile-menu-open" : ""}`}>
      <AppMenuHeader />
      <AppMenuItems />
      <AppMenuFooter />
    </div>
  );
};

export default AppMenu;
