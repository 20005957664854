const overrideToLocaleString = Number.prototype.toLocaleString;

// eslint-disable-next-line no-extend-native
Number.prototype.formatTutarGoster = function (places = 2) {
  let result = overrideToLocaleString.call(this, "tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: places,
    maximumFractionDigits: places,
  });
  return result.replace("₺", "");
};

export function buildFormData(formData, data, parentKey) {
  if (data && typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          buildFormData(formData, item, `${fullKey}[${index}]`);
        });
      } else {
        buildFormData(formData, data[key], fullKey);
      }
    });
  } else if (data instanceof File) {
    formData.append(parentKey.replace("[file]", ".file"), data);
  } else {
    formData.append(parentKey, data);
  }
}
